import React from "react";
import "./spinner.css";

export default function SpinnerTTS() {
    return (
        <div className="tts-spinner">
            <div className="ts-window">
                <div className="ts-border">
                    <div className="ts-container">
                        <span>TTS</span>
                        <div className="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
